<template>
  <q-dialog
    v-model="isOpen"
    maximized
    persistent
  >
    <q-card>
      <q-card-section class="text-center q-pb-none">
        <h6 class="q-my-none">
          {{ $t(title) }}
        </h6>
      </q-card-section>

      <q-card-section v-if="!options.noSearch">
        <q-input
          standout="bg-teal text-white"
          :model-value="options.search"
          :label="$t('Search')"
          :debounce="300"
          @update:model-value="handleSearch"
        />
      </q-card-section>

      <q-card-section class="q-pa-none border">
        <q-table
          row-key="id"
          :rows="items"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="isLoading"
          :filter="filter"
          :rows-per-page-options="[]"
          table-header-class="d-none"
          virtual-scroll
          binary-state-sort
          flat
          dense
          @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
              showing
              color="primary"
            />
          </template>
          <template v-slot:top-row>
            <q-tr>
                <q-td>
                  <p>
                    {{ $t('Total Quantity:') }}
                  </p>
                </q-td>
              <q-td>
                <p>
                  {{quantityTotal}}
                </p>
              </q-td>

            </q-tr>
            <q-tr>
              <q-td>
                <p>
                  {{ $t('Total Expected Quantity:') }}
                </p>
              </q-td>
              <q-td>
                <p>
                  {{quantityExpectedTotal}}
                </p>
              </q-td>
              <q-td></q-td>
            </q-tr>
          </template>
          <template v-slot:body="props">
            <q-tr
              :props="props"
              class="clickable"
              @click="handleItem(props.row)"
            >
              <q-td
                key="image"
                :props="props"
                auto-width
              >
                <img
                  :src="props.row.image ? props.row.image : fallbackImage"
                  style="width: 60px; height: 60px; object-fit: contain;"
                  @error="onImageLoadFailure"
                >

                <div class="text-center">
                  {{ props.row.type }}
                </div>
              </q-td>

              <q-td
                key="details"
                :props="props"
                style="max-width: 200px; word-wrap: break-word; white-space: normal !important;"
              >
                <div class="text-subtitle1">
                  {{ `${props.row.name} (${props.row.id})` }}
                </div>

                <div class="text-caption">
                  {{ $t('Article') + ': ' + (props.row.article || '') }}
                </div>

                <div class="text-caption">
                  {{ $t('SKU') + ': ' + ((props.row._embedded.productOffer || {}).sku || '') }}
                </div>

                <div class="text-caption">
                  {{ $t('Barcode') + ': ' + (props.row.sku || '') }}
                </div>
              </q-td>

              <q-td
                key="items"
                :props="props"
                auto-width
              >
                <div>
                  {{ $t('Quantity') + ': ' + props.row.count }}
                </div>

                <div>
                  {{ $t('Expected') + ': ' + props.row.expected }}
                </div>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>

      <q-card-actions align="center">
        <q-btn
          color="negative"
          :label="$t(options.noSelect ? 'Close' : 'Cancel')"
          no-caps
          @click="handleClose"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AcceptanceItemsModal',
  data () {
    return {
      title: 'Purchase Order content',
      fallbackImage: 'assets/img/fallback-image/package.png',
      isOpen: false,
      isLoading: false,
      items: [],
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      filter: '',
      columns: [
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'center'
        },
        {
          label: this.$t('Details'),
          name: 'details',
          align: 'left'
        },
        {
          label: this.$t('Quantity'),
          name: 'items',
          align: 'center'
        }
      ],
      quantityTotal:0,
      quantityExpectedTotal:0,
      options: {}
    }
  },
  computed: {
    ...mapGetters([
      'terminalContainerHeight'
    ])
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    },
    resolve () {},
    reject () {},
    handleSearch (search) {
      this.options.search = search
      return this.onRequest({ pagination: { page: 1 } })
    },
    onRequest (data = { pagination: {} }) {
      this.pagination = data.pagination || {}
      const query = this.$utils.buildQuery(this.pagination)

      if (!query.filter) {
        query.filter = []
      }

      if (this.options.acceptance && !query.filter.find(({ field }) => field === 'document')) {
        query.filter.push({ type: 'eq', field: 'document', value: this.options.acceptance })
      }

      let search = this.options.search

      if (search && search[search.length - 1] !== '*' && search[search.length - 2] !== ':' && !search.includes('%')) {
        search += search[search.length - 1] === ':'
          ? '*'
          : ':*'

        query.search = search
      } else if(search.includes('%')) {
        query.search = search
      }

      return this.$service.acceptanceItem.getAll(query, this.options.place && this.options.place.id)
        .then(({ page, totalItems, items }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }

          const test= items.reduce((acc, e)=> {
            acc+=e.count
            return acc
          },0)
          this.quantityTotal= test

          const test2= items.reduce((acc, e)=>{
            acc+=e.quantityExpected
            return acc
          },0)
          this.quantityExpectedTotal= test2
          this.items = items
          if (totalItems === 0) {
            this.addErrorNotification('Products with that name are not founded!')
          }
        })
    },
    show (options = { search: '', document: null }) {
      this.options = options
      this.isOpen = true
      this.items = []
      this.pagination = {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      }

      this.title = options.title || 'Purchase Order content'

      if (options.defaultState) {
        this.pagination = {
          descending: true,
          page: options.defaultState.page,
          rowsPerPage: 25,
          rowsNumber: options.defaultState.totalItems
        }

        this.items = options.defaultState.items
      } else {
        this.onRequest({ pagination: { per_page: 25, page: 1 } })
      }

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    handleClose () {
      this.isOpen = false
      this.resolve(null)
    },
    handleItem (item) {
      if (this.options.noSelect) {
        return
      }

      this.isOpen = false
      this.resolve(item)
    }
  }
}
</script>
